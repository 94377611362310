import { render, staticRenderFns } from "./zasady-spracovania.vue?vue&type=template&id=66e92cda&scoped=true"
import script from "./zasady-spracovania.vue?vue&type=script&lang=js"
export * from "./zasady-spracovania.vue?vue&type=script&lang=js"
import style0 from "./zasady-spracovania.vue?vue&type=style&index=0&id=66e92cda&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e92cda",
  null
  
)

export default component.exports